<template>
  <div>
    <el-table v-loading="tableLoading" :data="data" stripe border @row-dblclick="edit">
      <el-table-column label="编号名称" prop="billName"></el-table-column>
      <el-table-column label="是否启用">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isUse?'info':'danger'">{{scope.row.isUse?'启用':'未启用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="编号前缀" prop="prefix"></el-table-column>
      <el-table-column label="日期格式">
        <template slot-scope="scope">{{getDateType(scope.row.dateType)}}</template>
      </el-table-column>
      <el-table-column label="日期分割符" prop="dateDivision"></el-table-column>
      <el-table-column label="编号后缀" prop="suffix"></el-table-column>
      <el-table-column label="顺序号格式" prop="orderForm"></el-table-column>
      <el-table-column label="示例" show-overflow-tooltip>
        <template slot-scope="scope">{{preView(scope.row)}}</template>
      </el-table-column>
    </el-table>
    <div>
      <el-dialog
        :title="'编辑【'+item.billName+'】'"
        :visible.sync="visible"
        append-to-body
        v-dialogDrag
        width="30%"
        :close-on-click-modal="false"
      >
        <el-form :model="item" status-icon ref="form" label-width="100px">
          <el-form-item label="编号名称">
            <el-input v-model="item.billName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="是否启用">
            <el-radio-group v-model="item.isUse">
              <el-radio :label="true">启用</el-radio>
              <el-radio :label="false">不启用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="编号前缀">
            <el-input v-model="item.prefix" clearable></el-input>
          </el-form-item>
          <el-form-item label="日期格式">
            <el-select v-model="item.dateType">
              <el-option v-for="d in dateTypeList" :key="d.val" :value="d.val" :label="d.lable"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期分割符">
            <el-input v-model="item.dateDivision" clearable></el-input>
          </el-form-item>
          <el-form-item label="编号后缀">
            <el-input v-model="item.suffix" clearable></el-input>
          </el-form-item>
          <el-form-item label="顺序号格式">
            <el-select v-model="item.orderForm">
              <el-option v-for="d in orderFormList" :key="d" :value="d" :label="d"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预览">{{itemPreView}}</el-form-item>
          <el-form-item>
            <el-button type="info" @click="save" :loading="saveLoading">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      tableLoading: false,
      data: [],
      dateTypeList: [
        {
          val: "",
          lable: "不使用"
        },
        {
          val: "yyyy*MM*dd",
          lable: "年月日"
        },
        {
          val: "yyyy*MM",
          lable: "年月"
        },
        {
          val: "yyyy",
          lable: "年"
        }
      ],
      orderFormList: ["00", "000", "0000", "00000"],
      item: {},
      visible: false,
      saveLoading: false
    };
  },
  computed: {
    itemPreView() {
      return this.preView(this.item);
    }
  },
  mounted() {
    this.getDataArray();
  },
  methods: {
    getDataArray() {
      this.tableLoading = true;
      this.$get("Sys/GetAllBillCode")
        .then(r => {
          this.data = r;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getDateType(val) {
      let res = "";
      this.dateTypeList.forEach(d => {
        if (d.val == val) {
          res = d.lable;
        }
      });
      return res;
    },
    preView(item) {
      let date = "";
      if (item.dateType) {
        let division = item.dateDivision ? item.dateDivision : "";
        let format = item.dateType.replace(/\*/g, division).toUpperCase();
        date = this.$moment(new Date()).format(format);
      }
      let order = "";
      if (item.orderForm) {
        order = item.orderForm.substring(0, item.orderForm.length - 1) + "1";
      }
      return (
        (item.prefix ? item.prefix : "") +
        date +
        (item.suffix ? item.suffix : "") +
        order
      );
    },
    edit(row) {
      this.item = { ...row };
      this.visible = true;
    },
    save() {
      this.saveLoading = true;
      this.$post("Sys/UpdateBillCode", this.item)
        .then(r => {
          this.visible = false;
          this.$message.success("操作成功！");
          this.getDataArray();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>

<style>
</style>